<template>
  <div class="loginPageBox">
    <h1 class="logoBox">
      <img :src="$localPicture+'logo/logo_white.png'" alt="logo">
    </h1>
    <div class="left">
      <p class="title">
        仓储管理 —— WMS云
      </p>
      <p class="describe">
        智能仓储管理中枢，全面适配灵活高效
      </p>
    </div>
    <div class="right">
      <!-- 上面可点击的tab -->
      <div class="titleTab">
        <span
          v-for="(item, index) in loginTabArr"
          :key="index"
          :class="activeIndex === index ? 'active' : ''"
          @click="getActiveIndex(index)"
        >{{ item }}</span>
      </div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="0"
        class="demo-ruleForm"
      >
        <!-- 账户密码登录 -->
        <div v-if="activeIndex === 0" class="passwordLogin">
          <el-form-item class="account" label="" prop="userCode">
            <img class="icon" src="@/assets/icon/ren.png" alt="icon">
            <el-input v-model="ruleForm.userCode" placeholder="请输入账户名" />
          </el-form-item>
          <el-form-item class="account" label="" prop="password2">
            <img class="icon" src="@/assets/icon/possword.png" alt="icon">
            <img
              class="lookImg"
              src="@/assets/icon/look.png"
              alt="icon"
              @click="lookPassword = !lookPassword"
            >
            <el-input
              v-model="ruleForm.password2"
              :type="lookPassword ? 'text' : 'password'"
              placeholder="请输入密码"
            />
          </el-form-item>
          <!-- <el-form-item class="password" label="" prop="kaptcha">
            <img
              :src="ruleForm.codeimg"
              alt
              class="imageCode"
              @click="getVerificationCode()"
            >
            <img
              class="icon"
              src="@/assets/icon/xin.png"
              alt="icon"
            >
            <el-input v-model="ruleForm.kaptcha" placeholder="请输入验证码" />
          </el-form-item> -->
          <slide-verify :refresh-slide="refreshSlide" @moveCheck="moveCheck" />
        </div>
        <!-- 手机号码登录 -->
        <div v-else-if="activeIndex === 1" class="mobilePhoneLogin">
          <el-form-item class="account2" label="" prop="phoneNo">
            <img class="icon" src="@/assets/icon/phone1.png" alt="icon">
            <el-input v-model="ruleForm.phoneNo" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item class="password2" label="" prop="code">
            <img
              class="icon"
              src="@/assets/icon/xin.png"
              alt="icon"
              @click="lookPassword = !lookPassword"
            >
            <span :class="['code',disabled?'activeCode':'']" @click="getCode">{{ codeStr }}</span>
            <el-input
              v-model="ruleForm.code"
              type="number"
              maxlength="6"
              placeholder="请输入验证码"
            />
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            class="loginButton"
            :class="(Object.keys(ruleForm.imageCaptchaTrackReq).length == 0 && activeIndex === 0) ? 'prohibit': 'operate'"
            :loading="$store.state.loading"
            @click="goLogin('ruleForm')"
          >
            登录
          </el-button>
          <!-- <router-link class="registerButton" :to="{ path: '/register' }">
            <span>无账号？</span>
            注册账号
          </router-link> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCodes, login, sendCodeLogin, phoneLogin } from '@/api/login'
import { rules } from '@/unit/matchFormRegex'
import { encrypt } from '@/unit/index'
import SlideVerify from '@/components/SlideVerify.vue'
export default {
  components: { SlideVerify },
  data() {
    return {
      loginOrRegister: false,
      agreeProtocol: false,
      codeTitle: '获取验证码',
      ruleForm: {
        codeimg: '',
        password2: '',
        userCode: '',
        kaptcha: '',
        code: '',
        phoneNo: '',
        imageCaptchaTrackReq: {}
      },
      loginTabArr: ['账号密码登录', '手机号登录'],
      activeIndex: 0,
      lookPassword: false,
      codeStr: '获取验证码',
      disabled: false,
      rules: {
        password2: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        userCode: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          {
            min: 5,
            max: 25,
            message: '长度在 5 到 25 个字符',
            trigger: 'blur'
          }
        ],
        phoneNo: [...rules.phone],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ]
      },
      refreshSlide: null
    }
  },
  mounted() {
    // this.getVerificationCode()
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.disabled) return
      let num = 60
      if (!this.ruleForm.phoneNo || this.ruleForm.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      this.disabled = true
      sendCodeLogin(this.ruleForm.phoneNo, (res) => {
        this.ruleForm.code = res.msg
        this.timer = setInterval(() => {
          if (num <= 1) {
            clearInterval(this.timer)
            this.timer = null
            this.codeStr = '获取验证码'
            this.disabled = false
            return
          }
          num--
          this.codeStr = num >= 10 ? num + 's' : '0' + num + 's'
        }, 1000)
      })
    },
    // 点击tab栏
    getActiveIndex(index) {
      this.activeIndex = index
      this.$refs['ruleForm'].resetFields()
    },
    // 点击登录呀
    goLogin(formName) {
      if (Object.keys(this.ruleForm.imageCaptchaTrackReq).length === 0 && this.activeIndex === 0) return
      // 登录之前把注册置空
      sessionStorage.setItem('tokenWms', null)
      this.$store.commit('getToken', null)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.activeIndex === 0) this.ruleForm.password = encrypt(this.ruleForm.password2)
          this.ruleForm.cmpRole = '09'
          const api = this.activeIndex === 0 ? login : phoneLogin
          api(this.ruleForm, (res) => {
            this.$store.commit('getToken', res.data.token)
            localStorage.setItem('userInfoWms', JSON.stringify(res.data))
            if (res.data.cmpState === '01') {
              this.$message.success(
                '您的基本信息暂未录入完毕，请先补齐基本信息！'
              )
            }
            switch (res.data.cmpState) {
              case '01':
                this.$router.push({
                  path: '/enterpriseInformation/addInformation'
                })
                break
              default:
                this.$router.push({ path: '/' })
                break
            }
          }, () => {
            // this.getVerificationCode()
            this.refreshSlide = String(new Date())
            this.ruleForm.imageCaptchaTrackReq = {}
          })
        }
      })
    },
    // 获取验证码
    getVerificationCode() {
      getCodes((res) => {
        this.ruleForm.codeimg = res.captchaBase64
        this.ruleForm.key = res.key
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 获取校验数据
    moveCheck(data) {
      this.ruleForm.imageCaptchaTrackReq = data
    }
  }
}
</script>

<style lang="scss">
.loginPageBox {
  position: relative;
  background: url(../../assets/image/bg5.png) no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .right {
    position: absolute;
    top: 50%;
    right: 11.56%;
    transform: translateY(-50%);
    width: 400px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 8px 15px 51px #c5c5c5;
    padding: 12px 0 24px;
    .el-form {
      width: calc(100% - 70px);
      margin-left: 35px;
      .account {
        margin-bottom: 16px;
      }
      .password {
        margin-bottom: 30px;
      }
      .account2 {
        margin-bottom: 28px;
      }
      .password2 {
        margin-bottom: 42px;
      }

      .el-form-item__content {
        position: relative;

        .loginButton {
          display: inline-block;
          width: 100%;
          height: 46px;
          color: #fff;
          background-color: #e1251b;
          cursor: pointer;
          font-size: 15px;
          text-align: center;
          border: 0;
          &.prohibit {
            cursor: default;
            background-color: rgba(0,0,0,.2);
          }
        }

        .registerButton {
          width: 100%;
          display: block;
          text-align: center;
          color: #e1251b;
          font-size: 12px;
          span {
            color: #999;
          }
        }

        img {
          position: absolute;
          top: 16px;
          z-index: 12;
          height: 18px;
          width: 18px;
        }

        .code {
          position: absolute;
          top: 19px;
          right: 24px;
          display: inline;
          z-index: 12;
          line-height: 1;
          font-size: 12px;
          color: #0089ff;
          cursor: pointer;
        }
        .activeCode{
          color: #ccc;
        }
        .imageCode {
          position: absolute;
          top: 0;
          right: 0;
          height: 48px;
          width: 120px;
          z-index: 223232;
        }

        .icon {
          left: 15px;
        }

        .icon2 {
          right: 89px;
        }

        .lookImg {
          right: 18px;
          cursor: pointer;
        }
      }

      .el-input__inner {
        text-indent: 31px;
        height: 48px;
        background-color: #fff;
        border: 1px solid #f0f0f0 !important;
      }

      .el-input__inner:focus {
        border: 1px solid #f0f0f0 !important;
      }
    }

    .titleTab {
      padding: 40px 35px 35px;
      width: calc(100% - 70px);
      display: flex;

      span {
        position: relative;
        color: #747774;
        font-size: 16px;
        flex: 1;
        text-align: center;
        cursor: pointer;
      }

      span::after {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        bottom: -11px;
        width: 24px;
        height: 2px;
        content: "";
      }

      .active {
        color: #e1251b;
      }

      .active::after {
        background-color: #e1251b;
      }
    }
  }
  .left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8.95%;
    color: #fff;
    .describe {
      padding: 20px 0 30px;
      font-size: 40px;
    }
    .title {
      font-size: 64px;
      font-weight: 500;
    }
  }
  .logoBox {
    position: absolute;
    top: 16%;
    left: 8.95%;
    z-index: 8;
    width: 320px;
    img {
      width: 100%;
    }
  }
}
</style>
