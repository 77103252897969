import { postRequest, getRequest } from './index'
import { BSERURL } from '@/config'
import axios from 'axios'
// 用户注册呀
export const register = (data, successCallback, failureCallback) => {
  postRequest('/cmp/register', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取验证码
export const getCodes = (successCallback, failureCallback) => {
  getRequest('/sys/captcha', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户登录呀
export const login = (data, successCallback, failureCallback) => {
  postRequest('/cmp/login', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 退出登录接口
export const loginOut = (data, successCallback, failureCallback) => {
  postRequest('/sys/logout', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 修改密码
export const updatePassword = (data, successCallback, failureCallback) => {
  postRequest('/user/updatePassword', { params: data }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息详情
export const cmpBaseinfoDetail = (cmpCode, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/detail', { cmpCode }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息修改
export const cmpBaseinfoAddAndEdit = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取用户详情
export const getUserDetail = (successCallback, failureCallback) => {
  getRequest('/user/getUserDetail', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发送手机验证码
export const sendPhoneCode = (phone, successCallback, failureCallback) => {
  postRequest('/cmp/sendCode?phone=' + phone, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户手机号码注册呀
export const phoneLogin = (data, successCallback, failureCallback) => {
  postRequest(`/cmp/phone/login`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 登录获取验证码
export const sendCodeLogin = (phone, successCallback, failureCallback) => {
  postRequest(`cmp/sendCode/login?phone=${phone}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 生成验证码图片
export const generateCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    postRequest(`sys/generateCaptchaImage`, data, res => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}

// 校验验证码图片
export const validCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${BSERURL}sys/validCaptchaImage`, data).then((res) => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}
